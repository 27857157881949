<template>
  <div>
    <router-view />
  </div>
</template>

<script>

import { invalidateToken, setToken } from '@/tools/request';
import { getTokenToObj } from '@/tools/utils'
import { setCookie } from '@/tools/cookie'
import { getUrlParams } from './shared';
import { redirectUrlByAllocationStrategy, queryByToken, redirectUrlMap, loginWhitePath } from '@/apis';

export default {
  name: 'Matrixh5',
  data() {
    return {}
  },
  created() {
    this.loginStateCheck();
  },
  methods: {
    async loginStateCheck(){
      const urlParams = getUrlParams(this.$route.query) || {};
      const { channelCode, loginType = 1 } = urlParams;
      // 判断新老token是否一致，不一致的话无效化token
      const tokenObj = getTokenToObj();
      const { 
        channelCode: cachedChannelCode, 
        loginType: cachedLoginType = 1, 
        roleType : cachedRoleType, 
        allocationStrategy: cacheAllocationStrategy
      } = tokenObj || {};
      const currentPath = this.$route.path;
      if(loginWhitePath.includes(currentPath)) return;
      try {
        // 接口自带当前token，根据后端的token有效期进行校验，若此接口报错或返回用户未登录则此用户token已失效，需重新登录
        const res = await queryByToken() || {};
        if (res.code !== 0) {
          invalidateToken();
          this.$router.replace({ path: '/login', query: {
            channelCode: channelCode ? channelCode : cachedChannelCode,
            loginType: loginType ? loginType : cachedLoginType
          }})
          return;
        }
        // 是登录页
        if(currentPath === '/login'){
          // 之前登录过，存了上一次的token
          if (channelCode && cachedChannelCode) {
            // 通道号和loginType不同就重新登录
            if (channelCode !== +cachedChannelCode || loginType !== +cachedLoginType) {
              invalidateToken();
              return;
            }else{
              // 相同直接跳转，不用登录
              location.replace(decodeURIComponent(redirectUrlMap[loginType]));
              return;
            }
          }
        }
        // 是刷新其他页面
        else{
          // 若没有token，去登录页
          if(!tokenObj){
            invalidateToken();
            this.$router.replace({ path: '/login', query: {
              channelCode: channelCode ? channelCode : cachedChannelCode,
              loginType: loginType ? loginType : cachedLoginType
            }})
            return;
          }
          // 刷新token
          const { collectType, roleType, allocationStrategy } = res.data;
          const newTokenObj = {
            ...tokenObj,
            collectType,
            roleType,
            allocationStrategy
          }
          let tokenStr = ''
          Object.keys(newTokenObj).forEach(key => {
            if (newTokenObj[key] !== null) {
              setCookie(key, newTokenObj[key])
              if (tokenStr === '') {
                tokenStr += `${key}=${newTokenObj[key]}`
              } else {
                tokenStr += `;${key}=${newTokenObj[key]}`
              }
            }
          })
          setToken(tokenStr)
          // 纠正跳转
          redirectUrlByAllocationStrategy(currentPath)
          // 若新老角色缓存不一致，需重新刷新
          if(+cacheAllocationStrategy !== allocationStrategy || +cachedRoleType !== roleType){
            this.$router.go(0);
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style rel="stylesheet/less" lang="less" scoped></style>
